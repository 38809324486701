import { useQuery } from '@tanstack/vue-query'
import QueryKeys from '@/vue-query/QueryKeys'
import { useProjectOverviewApi } from '../api/useProjectOverviewApi'

export function useProjectOverviewQuery(projectId: Ref<string>) {
    const client = useProjectOverviewApi()
 
    const isEnabled = computed(() => !!projectId.value)
 
    return useQuery([QueryKeys.ProjectOverviews, projectId], () => client.getProjectOverview(projectId.value), { enabled: isEnabled, staleTime: 10 * 60 * 1000, refetchInterval: 60 * 1000 })
 }